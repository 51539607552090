import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useAlertDialog } from './AlertDialogContext';

const AlertDialog = () => {
  const { dialogState, hideAlertDialog } = useAlertDialog();
  const { open, title, content, onConfirm } = dialogState;

  return (
    <Dialog open={open} onClose={hideAlertDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        <p>{content}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onConfirm();
            hideAlertDialog();
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;