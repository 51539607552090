import React, { createContext, useContext, useState, useEffect } from 'react';

const VoiceContext = createContext();

export const VoiceProvider = ({ children }) => {
    const [selectedVoice, setSelectedVoice] = useState(null);

    useEffect(() => {
        const updateVoices = () => {
            const availableVoices = window.speechSynthesis.getVoices();
            const enUSVoices = availableVoices.filter(voice => voice.lang.startsWith('en-US'));
            setSelectedVoice(enUSVoices.length > 0 ? enUSVoices[0] : availableVoices[0]);
        };

        window.speechSynthesis.onvoiceschanged = updateVoices;
        updateVoices();

        return () => {
            window.speechSynthesis.onvoiceschanged = null;
        };
    }, [setSelectedVoice]);

    return (
        <VoiceContext.Provider value={{ selectedVoice }}>
            {children}
        </VoiceContext.Provider>
    );
};

export const useVoice = () => useContext(VoiceContext);