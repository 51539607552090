import Navbar from './Navbar'
import * as React from 'react';
import { Box } from '@mui/material';
import LoginDialog from './dialogs/LoginDialog';
import { useAuth } from './auth/AuthContext';
import PaginatedTable from './data/PaginatedTable';
import RatingToRender from './data/RatingToRender';
import { useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIconOutlined from '@mui/icons-material/VisibilityOutlined';
import Tooltip from '@mui/material/Tooltip';
import LocalTimeConverter from './data/LocalTimeConverter';

export default function InterviewRecordList() {
    const { isAuthenticated, userInfo, Logout } = useAuth();
    const { challengeId } = useParams();

    const columns = [
        {
            field: 'user_id', headerName: 'User',
            renderCell: (row) => {
                return String(row.user_id) === String(userInfo.sub) ?
                    <Tooltip title="This is your submission"><PersonIcon /></Tooltip> :
                    <Tooltip title="This is a shared submission from others"><VisibilityIconOutlined /></Tooltip>;
            }
        },
        { field: 'rating', headerName: 'Rating', renderCell: (row) => { return <RatingToRender rating={row.rating} useIcon={true} /> } },
        { field: 'time', headerName: 'Time', renderCell: (row) =>{ return <LocalTimeConverter utcTime={row.time} /> } },
    ];

    const fetchData = React.useCallback(async (page, rowsPerPage) => {
        try {
            const response = await fetch('/api/records/challenge?challengeId=' + challengeId + '&page=' + page + '&rowsPerPage=' + rowsPerPage);
            if (response.status === 401) {
                Logout();
                return null;
            }
            const result = await response.json();
            if (result.error) {
                throw result.error;
            }
            return {
                data: result.data,
                total: result.total,
                // title: "Solutions for [" + challengeId + "] " + result.title,
                title: "Solutions for " + result.title,
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    }, [Logout, challengeId]);

    return (
        <>
            <Navbar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 0, // 子Box之间的间距
                    mt: 1,
                }}
            >
                <Box
                    width="90vw"
                    maxWidth="900px"
                    sx={{
                        m: 5
                    }}
                >
                    {isAuthenticated &&
                        <PaginatedTable
                            rowsPerPage={20}
                            columns={columns}
                            fetchData={fetchData}
                            isRowClickable={(row) => { return true; }}
                            onRowClick={(row) => {
                                window.location.href = `/interview/record/${row.id}`;
                            }}
                        />}
                </Box>
            </Box>
            <LoginDialog />
        </>
    )
}