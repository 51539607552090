import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination
} from '@mui/material';
import { useAlertDialog } from '../dialogs/AlertDialogContext';

const PaginatedTable = ({ columns, fetchData, onRowClick, isRowClickable, rowsPerPage }) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const { showAlertDialog } = useAlertDialog();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const result = await fetchData(page, rowsPerPage);
        if (result) {
          if (result.error) {
            throw new Error(result.error);
          }
          if (result.data) {
            setData(result.data);
          }
          if (result.total) {
            setTotal(result.total);
          }
          if (result.title) {
            setTitle(result.title);
          }
        } else {
          showAlertDialog('Error', 'Error loading data to the table');
        }
      } catch (error) {
        showAlertDialog('Error', 'Error loading data to the table: ' + error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [page, rowsPerPage, fetchData, showAlertDialog]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      { title.length > 0 &&
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              padding: 3,
            }}><b>{title}</b></Box>}
      <TableContainer>
        {loading ? (
          <Box sx={{ width: "100%" }}>
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
          </Box>
        ) : (
          <>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.field}>{column.headerName}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  const clickable = isRowClickable ? isRowClickable(row) : false;
                  return (
                    <TableRow
                      key={row.id}
                      onClick={() => clickable && onRowClick && onRowClick(row)}
                      sx={{
                        cursor: clickable ? 'pointer' : 'default',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.08)'
                        },
                      }}
                    >
                      {columns.map((column) => (
                        <TableCell key={column.field} sx={{ border: "none" }}>
                          {column.renderCell ? column.renderCell(row) : row[column.field]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
      {rowsPerPage && rowsPerPage > 0 && !loading &&
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      }
    </>
  );
};

PaginatedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
    })
  ).isRequired,
  fetchData: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  isRowClickable: PropTypes.func,
};

export default PaginatedTable;
