import Navbar from './Navbar'
import * as React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import config from './config';

export default function Home() {
    const theme = useTheme()
    return (
        <>
        <Box sx={{
            minHeight: '100vh', 
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box>
                <Navbar />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    textAlign: 'center', 
                    backgroundColor: theme.palette.background.default,
                }}
            >
                    <Typography variant="h4"  gutterBottom>
                        Welcome to {config.websiteName}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Practice popular interview questions with our AI interviewer
                    </Typography>
                </Box>
            </Box>
        </>
    )
}
