import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Button, Input, Typography } from '@mui/material';
import { useAuth } from '../auth/AuthContext';
import Cookies from 'js-cookie';
import { useAlertDialog } from './AlertDialogContext';

const InputInvitationCode = ({ invitationCode, setInvitationCode, TryLogin }) => {
    const handleChange = useCallback((event) => {
        setInvitationCode(event.target.value);
    }, [setInvitationCode]);

    return (
        <Box>
            <Box display="flex" alignItems="center" mb={1}>
                <Input
                    placeholder="Invitation Code"
                    value={invitationCode}
                    onChange={handleChange}
                    sx={{ marginRight: 2 }}
                />
                <Button
                    color="primary"
                    onClick={TryLogin}
                >
                    Confirm
                </Button>
            </Box>
            <Box>
                <Typography>
                    This website is still experimental.
                </Typography>
            </Box>
        </Box>
    );
};

const useGoogleScript = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.onload = () => {
            console.log('Google Identity Services script loaded');
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
};

const GoogleLoginDiv = ({ ProcessGoogleLogin }) => {
    useGoogleScript();

    useEffect(() => {
        window.ProcessGoogleLogin = ProcessGoogleLogin;
    }, [ProcessGoogleLogin]);

    return (
        <div className="pane">
            <div id="g_id_onload"
                data-client_id="93558424263-4mop04a9sc37ja8sb7h03kkrdtrm28kh.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-callback="ProcessGoogleLogin"
                data-auto_prompt="false">
            </div>
            <div className="g_id_signin"
                data-type="standard"
                data-shape="rectangular"
                data-theme="outline"
                data-text="signin_with"
                data-size="large"
                data-logo_alignment="left">
            </div>
        </div>
    );
};

const LoginDialog = ({open = true, onClose=()=>{} }) => {
    const { showAlertDialog } = useAlertDialog();
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const [googleCredential, setGoogleCredential] = useState(null);
    const [askForInvitationCode, setAskForInvitationCode] = useState(false);
    const [invitationCode, setInvitationCode] = useState("");

    const TryLogin = useCallback(async () => {
        try {
            const response = await fetch('/api/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    credential: googleCredential,
                    ...(invitationCode.trim().length > 0 && { invitation_code: invitationCode.trim() }),
                }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const result = await response.json();
            if (result.invited !== true) {
                if (askForInvitationCode) {
                    showAlertDialog("Error", "Invalid invitation code.");
                } else {
                    setAskForInvitationCode(true);
                }
                return;
            }
            Cookies.set('jwt', result.jwt);
            setIsAuthenticated(true);
            setAskForInvitationCode(false);
        } catch (error) {
            console.error('Error during fetch:', error);
        }
    }, [googleCredential, invitationCode, askForInvitationCode, showAlertDialog, setIsAuthenticated]);

    const TryLoginRef = useRef(TryLogin);
    useEffect(() => {
        TryLoginRef.current = TryLogin;
    }, [TryLogin]);

    useEffect(() => {
        if (googleCredential) {
            TryLoginRef.current();
        }
    }, [googleCredential]);

    const ProcessGoogleLogin = useCallback((googleLoginResponse) => {
        setGoogleCredential(googleLoginResponse.credential);
    }, []);

    return (
        <>
            {!isAuthenticated && (
                <Dialog open={open} onClose={onClose} sx={{ overflow: 'hidden' }}>
                    <DialogTitle>
                        Login
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {askForInvitationCode ? (
                            <InputInvitationCode
                                invitationCode={invitationCode}
                                setInvitationCode={setInvitationCode}
                                TryLogin={TryLogin}
                            />
                        ) : (
                            <>
                            <Box sx={{justifyContent:"center", alignItems:"center", display:"flex"}}>
                                <GoogleLoginDiv ProcessGoogleLogin={ProcessGoogleLogin} />
                                </Box>
                                <hr />
                                <Typography>By logging in, you agree to the <a href="/terms" target="_blank">terms and conditions</a></Typography>
                                </>
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default LoginDialog;
