import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { useChatSettings } from './ChatSettingsContext';
import { PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { useVoice } from './VoiceContext';

const TextToSpeechToggle = ({ text }) => {
    const { settings } = useChatSettings();
    const [autoPlay, setAutoPlay] = useState(settings.autoPlaySpeech);
    const [isPlaying, setIsPlaying] = useState(false);
    const {selectedVoice} = useVoice();
    const theme = useTheme();

    const MAX_UTTERANCE_LENGTH = 180; // Define the maximum length of each utterance
    const MIN_UTTERANCE_LENGTH = 20;

    const createUtteranceQueue = useCallback((text) => {
        const punctuation = /[.?!,;:]/;
        const chunks = [];
        let chunk = '';

        for (let i = 0; i < text.length; i++) {
            chunk += text[i];

            if (
                punctuation.test(text[i]) && 
                (i + 1 === text.length || text[i + 1] === ' ') && 
                chunk.length > MIN_UTTERANCE_LENGTH // Ensure chunk is not too short
            ) {
                chunks.push(chunk.trim());
                chunk = '';
            } else if (chunk.length >= MAX_UTTERANCE_LENGTH) {
                chunks.push(chunk.trim());
                chunk = '';
            }
        }

        if (chunk) {
            chunks.push(chunk.trim());
        }

        return chunks.map(chunkText => {
            const utterance = new SpeechSynthesisUtterance(chunkText);
            utterance.voice = selectedVoice;
            return utterance;
        });
    }, [selectedVoice]);

    const playUtteranceQueue = (queue) => {
        if (queue.length === 0) return;

        const utterance = queue.shift();
        utterance.onend = () => {
            if (queue.length > 0) {
                playUtteranceQueue(queue);
            } else {
                setIsPlaying(false);
            }
        };

        window.speechSynthesis.speak(utterance);
        setIsPlaying(true);
    };

    const togglePlayback = () => {
        if (isPlaying) {
            window.speechSynthesis.cancel();
            setIsPlaying(false);
        } else {
            const queue = createUtteranceQueue(text);
            playUtteranceQueue(queue);
        }
    };

    useEffect(() => {
        if (autoPlay) {
            setAutoPlay(false);
            const queue = createUtteranceQueue(text);
            playUtteranceQueue(queue);
        }
    }, [autoPlay, text]);

    useEffect(() => {
        return () => {
            window.speechSynthesis.cancel();
        };
    }, []);

    return (
        <Box onClick={togglePlayback} flexShrink={1}
            sx={{ cursor: 'pointer', 
                display: settings.textToSpeech ? 'inline-flex' : 'none',
                paddingRight: 1,
            }}>
            {isPlaying ? <PauseCircleOutline color="primary" sx={{
                '&:hover': { color : theme.palette.primary.dark }
            }}
             /> : <PlayCircleOutline color="primary" sx={{
                '&:hover': { color : theme.palette.primary.dark }
            }} />}
        </Box>
    );
};

export default TextToSpeechToggle;
