import React, { createContext, useContext, useState } from 'react';
import {useAuth} from '../auth/AuthContext';
import { useAlertDialog } from '../dialogs/AlertDialogContext';
import { useDraw } from '../interview-tools/DrawContext';

const InterviewSessionContext = createContext();

export const InterviewSessionProvider = ({ children }) => {
  const [challengeId, setChallengeId] = useState(null);
  const [shared, setShared ] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const {showAlertDialog} = useAlertDialog();
  const [concluded, setConcluded] = useState(false);
  const {Logout} = useAuth();
  const { leavePage } = useDraw();

  const goToPage = (url) => {
    leavePage(); // remove draw DOM to prevent alert dialog.
    if (url === null || url === undefined) {
      window.location.reload();
    } else {
      window.location.href = url;
    }
  }

  const submitShare = async (shared) => {
    console.log('submit share')
    if (!recordId) {
      showAlertDialog('Error', 'Interview session is not saved yet.');
      return;
    }
    try {
        const response = await fetch('/api/record/share', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: recordId,
                shared: shared,
            }),
        });
        console.log(response);
        setShared(shared);
    } catch (error) {
        console.error('Error submit share:', error);
    }
  }


  const saveInterviewSession = async () => {
    try {
      const response = await fetch('/api/interview/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
      if (response.status === 401) {
        Logout();
        return;
      }
      const result = await response.json();
      if (result.error) {
        throw result.error;
      }
      setShared(false);
      setIsOwner(true);
      setRecordId(result.id);
      goToPage('/interview/record/' + result.id);
    } catch (error) {
      showAlertDialog('Error', 'Failed to submit: ' + error);
      console.error('Error sending message: ', error);
    }
  }

  return (
    <InterviewSessionContext.Provider
      value={{
        shared,
        setShared,
        isOwner,
        setIsOwner,
        recordId,
        setRecordId,
        saveInterviewSession,
        submitShare,
        concluded,
        setConcluded,
        challengeId,
        setChallengeId,
        goToPage,
      }}
    >
      {children}
    </InterviewSessionContext.Provider>
  );
};

export const useInterviewSession = () => useContext(InterviewSessionContext);
