import { Button } from '@mui/material';
import ExitToAppIconOutlined from '@mui/icons-material/ExitToAppOutlined';
import { useInterviewSession } from './InterviewSessionContext';
import { useConfirmDialog } from "../dialogs/ConfirmDialogContext";

export default function InterviewExitButton() {
  const {goToPage, recordId} = useInterviewSession();
  const {showConfirmDialog} = useConfirmDialog();

  const handleExitDialogOpen = () => {
    if (recordId !== null) {  // already saved the session.
      goToPage('/practice');
      return;
    }
    showConfirmDialog(
      'Exit Interview',
      'You are about to exit the interview. Any unsubmitted progress will be lost.',
      () => {
        goToPage('/practice');
      }
    );
  };

  return (
    <Button
      color="error"
      startIcon={<ExitToAppIconOutlined />}
      onClick={handleExitDialogOpen}
    >
      Exit
    </Button>);
}