import { Button } from '@mui/material';
import { useInterviewSession } from './InterviewSessionContext';
import VisibilityIconOutlined from '@mui/icons-material/VisibilityOutlined';

export default function SolutionsButton() {
    const { challengeId, goToPage } = useInterviewSession();
    const handleClick = () => {
        goToPage('/records/challenge/' + challengeId);
    };

    return (
        <Button
            color="primary"
            startIcon={<VisibilityIconOutlined />}
            disabled={challengeId === undefined || challengeId === null || challengeId <= 0}
            onClick={handleClick}
        >
            Solutions
        </Button>);
}