import React, { createContext, useContext, useState } from 'react';

const CodeContext = createContext();


export const CodeProvider = ({ children }) => {
  const [isCodeReadOnly, setIsCodeReadOnly] = useState(false);
  const [currentCode, setCurrentCode] = useState('');
  const [showCode, setShowCode] = useState(false);

  const handleCodeChange = (newCode) => {
    setCurrentCode(newCode);
  };

  return (
    <CodeContext.Provider
      value={{
        isCodeReadOnly,
        setIsCodeReadOnly,
        currentCode,
        setCurrentCode,
        handleCodeChange,
        showCode,
        setShowCode,
      }}
    >
      {children}
    </CodeContext.Provider>
  );
};

export const useCode = () => useContext(CodeContext);
