import React, { useState, useEffect } from 'react';
import { useDraw } from './DrawContext';
import { Box, Button, CircularProgress } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const DrawIO = () => {
  const { sendExportMessage, iframeRef, drawioUrl, drawioUrlWithParams, postMessage, isDrawReadOnly, currentDraw, readyToShow, SetReadyToShow } = useDraw();
  const [drawDataBase64, setDrawDataBase64] = useState("");

  useEffect(() => {
    async function handleInit(event) {
      if (event.origin !== drawioUrl) {
        return
      }
      // console.log('Received message:', event.data);
      try {
        const msg = JSON.parse(event.data);
        if (msg.event === 'configure') {
          if (isDrawReadOnly === true) {
            postMessage({ action: 'configure', config: {} })
          } else if (isDrawReadOnly === false) {
            postMessage({
              action: 'configure', config: {
                defaultLibraries: "uml;flowchart",
                enabledLibraries: [],
                libraries: [],
                enableCustomLibraries: false,
                appendCustomLibraries: false,
                expandLibraries: false,
                defaultGridEnabled: true,
                defaultGridSize: 10,
                css: `
                .geSketch div.geToolbarContainer:nth-of-type(5) { display: none !important; }
                .geSketch div.geToolbarContainer:nth-of-type(2) a.geToolbarButton:nth-of-type(1) { display: none !important; }
                .geSketch div.geToolbarContainer:nth-of-type(3) div.geToolbarButton:nth-of-type(1) { display: none !important; }
                .geSketch div.geToolbarContainer:nth-of-type(4) a.geToolbarButton:nth-of-type(7) { display: none !important; }
                .geSketch div.geToolbarContainer:nth-of-type(4) a.geToolbarButton:nth-of-type(9) { display: none !important; }
              `,
              }
            })
          }
        }
        if (msg.event === 'init') {
          if (isDrawReadOnly === true) {
            if (currentDraw === '') {
              return;
            }
            postMessage({ action: 'load', xml: currentDraw });
            const draw = await sendExportMessage();
            setDrawDataBase64(draw.data);
          } else {
            postMessage({ action: 'load', xml: "<mxGraphModel><root><mxCell id='0'/><mxCell id='1' parent='0'/></root></mxGraphModel>" });
          }
          window.removeEventListener('message', handleInit);
          SetReadyToShow(true);
        }
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    }

    window.addEventListener('message', handleInit);

    return () => {
      window.removeEventListener('message', handleInit);
    };
  }, []);

  return (
    <>
    <Box
      display={readyToShow ? "none" : "flex"}
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <CircularProgress />
    </Box>
    <Box
      display={readyToShow ? "flex" : "none"}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box flexGrow={1} alignItems="center" justifyContent="center" height="100%" width="100%">
        {isDrawReadOnly && <img src={drawDataBase64} style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />}
        <iframe
          id="DrawIO"
          title="DrawIO"
          ref={iframeRef}
          src={drawioUrlWithParams}
          sandbox="allow-scripts allow-same-origin"
          style={{ width: '100%', height: '100%', border: 'none', display: isDrawReadOnly ? 'none' : 'block' }} // Adjust the style as needed
        />
      </Box>
      {!isDrawReadOnly && <Box textAlign="center"><Button href="https://www.youtube.com/embed/KqM3YtYjpfA?si=OqfpG_uceW1-7zGf&amp;controls=0" target="_blank" endIcon={<OpenInNewIcon />}>Watch Demo Video</Button></Box>}
    </Box>
    </>
  );
}

export default DrawIO;