import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useConfirmDialog } from './ConfirmDialogContext';

const ConfirmDialog = () => {
  const { dialogState, hideConfirmDialog } = useConfirmDialog();
  const { open, title, content, onConfirm } = dialogState;

  return (
    <Dialog open={open} onClose={hideConfirmDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        <p>{content}</p>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={hideConfirmDialog}
        >
            Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            hideConfirmDialog();
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;