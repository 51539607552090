import React from 'react';
import { Dialog, CircularProgress, Box, Typography } from '@mui/material';

const LoadingDialog = ({open}) => {
    return (
        <Dialog open={open} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
                <CircularProgress />
                <Typography variant="h6" color="textPrimary" style={{ marginTop: '20px' }}>
                    Please wait...
                </Typography>
            </Box>
        </Dialog>
    );
};

export default LoadingDialog;