import React, { createContext, useContext, useState, useRef, useCallback } from 'react';
import InterviewDrawDecoder from './InterviewDrawDecoder'; // Adjust path as needed

const DrawContext = createContext();

function getBaseDrawioUrl(drawioUrlWithParams) {
  // Create a URL object
  const url = new URL(drawioUrlWithParams);
  
  // Return the base URL (origin)
  return `${url.protocol}//${url.host}`;
}

export const DrawProvider = ({ children, readonly }) => {
  const isDrawReadOnly = readonly;
  const iframeRef = useRef(null);
  const [currentDraw, setCurrentDraw] = useState('');
  const [showDraw, setShowDraw] = useState(false);
  const [readyToShow, SetReadyToShow] = useState(false);

  const drawioUrlWithParams = "https://draw.wujioffer.com/?embed=1&dev=0&test=0&ui=" + (readonly ? 'minimal' : 'sketch') + "&configure=" + (readonly ? 0 : 1) + "&embed=1&proto=json&saveAndExit=0&noSaveBtn=1&noExitBtn=1";
  // const drawioUrlWithParams = "https://embed.diagrams.net/?ui=sketch&configure=" + (readonly ? 0 : 1) + "&embed=1&proto=json&saveAndExit=0&noSaveBtn=1&noExitBtn=1"
  const drawioUrl = getBaseDrawioUrl(drawioUrlWithParams)

  const postMessage = useCallback((msg) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(JSON.stringify(msg), '*');
    }
  }, [iframeRef]);

  const sendExportMessage = useCallback(() => {
    const message =  { action: 'export', format: 'svg', embedImages: false, currentPage: true };
    return new Promise((resolve, reject) => {
      function handleMessage(event) {
        if (event.origin !== drawioUrl) {
          console.warn('Received message from unexpected origin:', event.origin);
          return; // Ignore messages from unexpected origins
        }
        try {
          const msg = JSON.parse(event.data);
          // console.log('Message received from iframe:', msg);
  
          // Check if this is the expected response
          if (msg.event === 'export') {
            window.removeEventListener('message', handleMessage);
            resolve(msg)
          }
        } catch (error) {
          console.error('Error parsing message:', error);
          reject(error);
        }
      }
  
      window.addEventListener('message', handleMessage);
  
      // Send the message to the iframe
      postMessage(message);
  
      // Set a timeout to reject the promise if no response is received within a certain time
      setTimeout(() => {
        window.removeEventListener('message', handleMessage);
        reject(new Error('Timeout waiting for response from iframe'));
      }, 10000); // 10 seconds timeout, adjust as needed
    });
  }, [drawioUrl, postMessage]);

  const isEmptyMxGraphModel = (xmlString) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
    
    // Check if there are any <mxCell> elements beyond the root ones
    const cells = xmlDoc.getElementsByTagName('mxCell');
    
    // In an empty model, there should only be two <mxCell> elements: the root and its child
    return cells.length === 2;
  };

  const setFixedDraw = useCallback((fixedDraw) => {
    if (fixedDraw && fixedDraw.length > 0) {
      setCurrentDraw(fixedDraw);
      setShowDraw(true);
    }
  }, [setShowDraw, setCurrentDraw]);

  const getCurrentDraw = useCallback(async () => {
    if (!showDraw || !readyToShow) {
      return '';
    }
    if (isDrawReadOnly) {
      return currentDraw;
    }
    // Get svg in DrawIO
    try {
      const draw = await sendExportMessage()
      // console.log('xml from drawIO:', draw.xml);
      const decodedDraws = await InterviewDrawDecoder(draw.xml);
      return isEmptyMxGraphModel(decodedDraws) ? '' : decodedDraws;
    } catch (error) {
      console.error('Error receiving response from iframe:', error);
      return '';
    }
  }, [showDraw, readyToShow, isDrawReadOnly, currentDraw, sendExportMessage]);

  const leavePage = () => {
    // workaround. this is to prevent alert dialog when leaving the page.
    if (iframeRef.current && iframeRef.current.parentNode) {
      iframeRef.current.parentNode.removeChild(iframeRef.current);
    }
  }

  return (
    <DrawContext.Provider
      value={{
        isDrawReadOnly,
        setFixedDraw,
        iframeRef,
        getCurrentDraw,
        currentDraw,
        setCurrentDraw,
        drawioUrl,
        drawioUrlWithParams,
        postMessage,
        leavePage,
        showDraw,
        setShowDraw,
        sendExportMessage,
        readyToShow,
        SetReadyToShow,
      }}
    >
      {children}
    </DrawContext.Provider>
  );
};

export const useDraw = () => useContext(DrawContext);
