import React, { useState } from 'react';
import './CodeMirrorComponent.css'
import { Controlled as ControlledCodeMirror } from 'react-codemirror2';
// import { UnControlled as UncontrolledCodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css'; // Basic CodeMirror CSS
import 'codemirror/theme/material.css'; // Example theme (adjust as needed)
import 'codemirror/theme/eclipse.css'
import 'codemirror/mode/javascript/javascript'; // Example mode (adjust as needed)
import 'codemirror/mode/clike/clike'; // This includes C, C++, Java, and similar languages
import 'codemirror/mode/python/python';
import 'codemirror/mode/ruby/ruby'; // Ruby
import 'codemirror/mode/php/php'; // PHP
import 'codemirror/mode/go/go'; // Go
import 'codemirror/mode/swift/swift'; // Swift
import 'codemirror/mode/sql/sql'; // SQL
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useCode } from './CodeContext';
require('./autorefresh.js');

const CodeMirrorComponent = () => {
  const { isCodeReadOnly, currentCode, setCurrentCode } = useCode();
  const [language, setLanguage] = useState('text/x-c++src');
  const [theme, setTheme] = useState('eclipse');

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  }

  // Refer: https://codemirror.net/5/doc/manual.html#config
  const options = {
    mode: language,
    theme: theme,
    // indentWithTabs: false, // 在缩进时，是否需要把 n*tab宽度个空格替换成n个tab字符，默认为false 。
    // smartIndent: false, // 自动缩进,回车时跟上一行保持相同缩进
    lineNumbers: true, // 左侧显示行数
    // matchBrackets: true, // 括号匹配
    lineWrapping: true,
    // electricChars: false,
    readOnly: isCodeReadOnly,
    autoRefresh: true,
  };

  const handleChange = (editor, data, value) => {
    if (!isCodeReadOnly) {
      setCurrentCode(value);
    }
  };

  return (
    
    <div className="code-block">
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label" sx={{color:'primary.main'}}>Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label="Language"
            onChange={handleLanguageChange}
          >
            <MenuItem value="javascript">JavaScript</MenuItem>
            <MenuItem value="python">Python</MenuItem>
            <MenuItem value="text/x-c++src">C++</MenuItem>
            <MenuItem value="text/x-csrc">C</MenuItem>
            <MenuItem value="text/x-java">Java</MenuItem>
            <MenuItem value="text/x-objectivec">Objective C</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="theme-label-id" sx={{color:'primary.main'}}>Theme</InputLabel>
          <Select
            labelId="theme-label-id"
            id="theme-id"
            value={theme}
            label="Theme"
            onChange={handleThemeChange}
          >
            <MenuItem value="eclipse">Eclipse</MenuItem>
            <MenuItem value="material">Material</MenuItem>
          </Select>
        </FormControl>
      </div>
            <ControlledCodeMirror className="code-block"
              value={currentCode}
              options={options}
              onBeforeChange={(editor, data, value) => {
                handleChange(editor, data, value);
              }}
              onChange={(editor, data, value) => {
                editor.refresh();
              }}
            />
    </div>
  );
}

export default CodeMirrorComponent;