import React from 'react';
import { Dialog, DialogTitle, Checkbox, DialogContent, DialogActions, Button, FormControlLabel } from '@mui/material';
import { useInterviewSession } from './InterviewSessionContext';
import config from '../config';
import { TextField, Box } from '@mui/material';
import CopyButton from '../dialogs/CopyButton';

const ShareRecordDialog = ({ open, onClose }) => {
    const { shared, submitShare, isOwner, recordId } = useInterviewSession();

    const handleShared = (event) => {
        submitShare(event.target.checked);
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Share Interview Record</DialogTitle>
            <DialogContent dividers={true}>
                <FormControlLabel
                    control={<Checkbox
                        checked={shared}
                        disabled={!isOwner}
                        onChange={handleShared}
                        sx={{
                            color: '#004C99',
                            '&.Mui-checked': {
                                color: '#0072E5',
                            },
                        }} />}
                    label="publicly share this submission" />
                <Box display="flex" alignItems="center">
                    <TextField
                        value={window.location.origin + "/interview/record/" + recordId}
                        disabled
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                    <CopyButton text={window.location.origin + "/interview/record/" + recordId} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShareRecordDialog;