import React from 'react';
import { Box, CssBaseline } from '@mui/material';

const CenteredYoutubeVideo = ({ url }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 100px)', // Full viewport height
      }}
    >
      <CssBaseline />
      <Box
        component="iframe"
        src={url}
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
          border: 0,
        }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
      />
    </Box>
  );
};

export default CenteredYoutubeVideo;
