import React, { createContext, useState, useContext } from 'react';

const ConfirmDialogContext = createContext();

export const ConfirmDialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState({
    open: false,
    title: '',
    content: '',
    onConfirm: () => {},
  });

  const showConfirmDialog = (title, content, onConfirm) => {
    setDialogState({ open: true, title, content, onConfirm });
  };

  const hideConfirmDialog = () => {
    setDialogState({ ...dialogState, open: false });
  };

  return (
    <ConfirmDialogContext.Provider value={{ dialogState, showConfirmDialog, hideConfirmDialog }}>
      {children}
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialog = () => {
  return useContext(ConfirmDialogContext);
};