import React, { createContext, useCallback, useContext, useState } from 'react';

const EvalContext = createContext();

export const EvalProvider = ({ children }) => {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState(null);


  const setEval = useCallback((rating, feedback) => {
    setRating(rating);
    setFeedback(feedback);
  }, [setRating, setFeedback]);

  return (
    <EvalContext.Provider
      value={{
        rating,
        feedback,
        setEval,
        setRating,
        setFeedback,
      }}
    >
      {children}
    </EvalContext.Provider>
  );
};

export const useEval = () => useContext(EvalContext);
