import { useCallback, useState, useEffect } from 'react';
import InterviewTools from './interview-tools/InterviewTools';
import ChatBox from './interview-chat/ChatBox';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import './PanelResizeHandle.css';
import { CssBaseline } from '@mui/material';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useAuth } from './auth/AuthContext';
import { useCode } from './interview-tools/CodeContext';
import { useDraw } from './interview-tools/DrawContext';
import { useEval } from './interview-tools/EvalContext';
import LoginDialog from './dialogs/LoginDialog';
import { useAlertDialog } from './dialogs/AlertDialogContext';
import { useInterviewSession } from './interview-session/InterviewSessionContext';
import { Stack } from '@mui/material';
import InterviewShareButton from './interview-session/ShareButton';
import InterviewPracticeButton from './interview-session/PracticeButton';
import InterviewExitButton from './interview-session/ExitButton';
import SurveyButton from './interview-session/SurveyButton';
import SolutionsButton from './interview-session/SolutionsButton';
import LoadingDialog from './dialogs/LoadingDialog';

export default function InterviewRecord() {
  const { isAuthenticated } = useAuth();
  const [fixedMessages, setFixedMessages] = useState("")
  const { showAlertDialog } = useAlertDialog()
  const [problemTitle, setProblemTitle] = useState("");
  const [problemDescription, setProblemDescription] = useState("")
  const { recordId } = useParams();
  const { setIsCodeReadOnly, setCurrentCode, setShowCode } = useCode();
  const { setFixedDraw } = useDraw();
  const { setEval } = useEval();
  const { concluded, setConcluded, setIsOwner, setShared, setRecordId, setChallengeId } = useInterviewSession();

  const GetInterviewRecord = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }
    try {
      const response = await fetch('/api/interview/record', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify((() => {
          let body = {
            RecordId: parseInt(recordId),
          };
          return body;
        })()),
      });
      const result = await response.json();
      if (result.error) {
        throw result.error;
      }
      setFixedMessages(result.chat_history);
      setProblemTitle(result.problem_title);
      setProblemDescription(result.problem_description);
      const toolData = JSON.parse(result.tool_data);
      if (toolData.code && toolData.code.length > 0) {
        setCurrentCode(toolData.code);
        setShowCode(true);
        setIsCodeReadOnly(true);
      }
      if (toolData.draw && toolData.draw.length > 0) {
        setFixedDraw(toolData.draw);
      }
      setConcluded(true);
      setIsOwner(result.is_owner);
      setShared(result.shared);
      setRecordId(parseInt(recordId));
      setEval(parseInt(result.rating), result.feedback);
      setChallengeId(result.challenge_id);
    } catch (error) {
      console.error('Error sending message:', error);
      showAlertDialog('Error', 'Failed to obtain interview record: ' + error, () => {
        window.location.href = '/'
      })
    }
  }, [recordId, isAuthenticated, setChallengeId, setConcluded, setCurrentCode, setEval, setFixedDraw, setIsCodeReadOnly, setIsOwner, setRecordId, setShared, setShowCode, showAlertDialog]);

  useEffect(() => {
    GetInterviewRecord();
  }, [GetInterviewRecord, isAuthenticated]);

  return (
    <>
      <CssBaseline />
      <Box display="flex"
        flexDirection="column"
        flexGrow="1"
        height="100vh">
        <Stack direction="row" spacing={2} justifyContent="center" className="buttons">
          <InterviewPracticeButton />
          <SolutionsButton />
          <InterviewShareButton />
          <InterviewExitButton />
          <SurveyButton />
        </Stack>
        <hr />
        <PanelGroup direction="horizontal">
          <Panel defaultSize={50} className="toolbox">
            <InterviewTools problemTitle={problemTitle} problemDescription={problemDescription} showFeedbackTab={true} />
          </Panel>
          <PanelResizeHandle className="panel-resize-handle panel-resize-handle-vertical" />
          <Panel defaultSize={50} className="chatbox">
            <ChatBox fixedMessages={fixedMessages} readOnly={true} />
          </Panel>
        </PanelGroup>
      </Box>
      <LoginDialog />
      <LoadingDialog open={isAuthenticated && !concluded} />
    </>
  );
}
