import React, { useState, useEffect, useRef, useCallback } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/system';
import { keyframes } from '@mui/system';
import { useAlertDialog } from '../dialogs/AlertDialogContext';

const fadeBorder = keyframes`
  0% {
    outline-color: rgba(25, 118, 210, 1.0); // 初始透明度
  }
  50% {
    outline-color: rgba(25, 118, 210, 0.1); // 中间透明度
  }
  100% {
    outline-color: rgba(25, 118, 210, 1.0); // 结束透明度
  }
`;

const TranscriptionComponent = ({ className, onTranscriptionChange, onTranscriptionStart, onTranscriptionEnd, disabled }) => {
    const [isListening, setIsListening] = useState(false);
    const recognition = useRef(null);
    const theme = useTheme();
    const {showAlertDialog} = useAlertDialog();

    const stopListening = useCallback(() => {
        if (recognition.current) {
            recognition.current.stop();
        }
        onTranscriptionEnd();
        setIsListening(false);
    }, [setIsListening, onTranscriptionEnd]);

    useEffect(() => {
        if (!('webkitSpeechRecognition' in window)) {
            showAlertDialog('Error', 'Web Speech API is not supported by this browser. Please use Chrome.')
            return;
        }

        if (!recognition.current) {
            recognition.current = new window.webkitSpeechRecognition();
            recognition.current.continuous = true;
            recognition.current.interimResults = true;
            recognition.current.lang = 'en-US';

            recognition.current.onresult = (event) => {
                let interimTranscript = '';
                let finalTranscript = '';

                const punctuationRegex = /[.,!?;:]$/;
                for (let i = event.resultIndex; i < event.results.length; i++) {
                    const transcript = event.results[i][0].transcript;
                    if (event.results[i].isFinal) {
                        if (!punctuationRegex.test(transcript.trim())) {
                            finalTranscript += transcript + '.'; // Adding period for punctuation
                        } else {
                            finalTranscript += transcript
                        }
                    } else {
                        interimTranscript += transcript;
                    }
                }
                if (onTranscriptionChange) {
                    onTranscriptionChange(interimTranscript, finalTranscript);
                }
            };

            recognition.current.onerror = (event) => {
                console.error('Speech recognition error', event.error);
                showAlertDialog('Error', 'Failed to enable speech recognition. Please make sure you have microphone ready and granted access correctly.')
                stopListening()
            };

            recognition.current.onend = () => {
                console.log('speech recognition onend');
                stopListening()
            };
        }
    }, [onTranscriptionChange]);

    const startListening = useCallback(() => {
        if (recognition.current) {
            recognition.current.start();
        }
        onTranscriptionStart();
        setIsListening(true);
    }, [setIsListening, onTranscriptionStart]);

    const RoundMicButton = useCallback(({ onClick }) => {
        return (
            <IconButton
                className={className}
                onClick={onClick}
                disabled={disabled}
                sx={{
                    backgroundColor: 'white',
                    color: theme.palette.primary.main,
                    animation: `${fadeBorder} 2s infinite`, // 动画时间和重复次数
                    width: '56px',
                    height: '56px',
                    outline: 1,
                    '&:hover': {
                        color: theme.palette.primary.dark, // 背景颜色在 hover 状态下变暗
                    },
                }}
            >
                <MicIcon />
            </IconButton>
        );
    }, [className, disabled, theme]);

    const RoundMicOffButton = useCallback(({ onClick }) => {
        return (
            <IconButton
                className={className}
                onClick={onClick}
                disabled={disabled}
                sx={{
                    backgroundColor: 'white',
                    color: theme.palette.secondary.main,
                    width: '56px',
                    height: '56px',
                    outline: 0,
                    '&:hover': {
                        color: theme.palette.secondary.dark, // 背景颜色在 hover 状态下变暗
                    },
                }}
            >
                <MicOffIcon />
            </IconButton>
        );
    }, [className, disabled, theme]);

    return (
        <>
            {isListening ? (
                <RoundMicButton onClick={stopListening} />
            ) : (
                <RoundMicOffButton onClick={startListening} />
            )}
        </>
    );
};

export default TranscriptionComponent;
