import { inflateRaw } from 'pako';

// Function to decode and decompress XML data similar to the Go function
async function InterviewDrawDecoder(xmlData) {
    try {
        // Parse XML data
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlData, 'application/xml');

        // Extract all diagram nodes from XML
        const diagramNodes = xmlDoc.querySelectorAll('diagram');
        if (diagramNodes.length === 0) {
            throw new Error('No diagram nodes found in XML');
        }

        // Decode each diagram node and store results in an array
        const decodedResults = Array.from(diagramNodes).map(diagramNode => {
            const decodedResult = decodeURIComponent(
                inflateRaw(Uint8Array.from(atob(diagramNode.innerHTML.trim()), c => c.charCodeAt(0)), { to: 'string' })
            );
            // URL decode the result if needed
            return decodeURIComponent(decodedResult);
        });

        // only returns the first page.
        return decodedResults[0];
    } catch (error) {
        console.error('Error decoding XML:', error);
        throw error;
    }
}

export default InterviewDrawDecoder;