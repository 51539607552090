import { useCallback, useState, useEffect } from 'react';
import InterviewTools from './interview-tools/InterviewTools';
import ChatBox from './interview-chat/ChatBox';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import './PanelResizeHandle.css';
import { CssBaseline } from '@mui/material';
import { useParams } from 'react-router-dom';
import InterviewMenu from './InterviewMenu';
import Box from '@mui/material/Box';
import { useAuth } from './auth/AuthContext';
import Joyride from 'react-joyride';
import { useCode } from './interview-tools/CodeContext';
import { useDraw } from './interview-tools/DrawContext';
import LoginDialog from './dialogs/LoginDialog';
import { useAlertDialog } from './dialogs/AlertDialogContext';
import { useEval } from './interview-tools/EvalContext';
import { useInterviewSession } from './interview-session/InterviewSessionContext';
import LoadingDialog from './dialogs/LoadingDialog';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const allSteps = [
  {
    target: '.interview-buttons',
    title: 'Interview Tutorial',
    content: 'Use these buttons to control the interview.',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.interview-timer-button',
    title: 'Interview Tutorial',
    content: 'Click this to start the timer for your own record.',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.interview-save-button',
    title: 'Interview Tutorial',
    content: 'Use this button to submit and finalize your interview. When the interview is still at an early stage, this button is not available.',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.chat-input-box',
    title: 'Interview Tutorial',
    content: "Use this to chat with the interviewer. You may use Markdown syntax if you prefer.",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.chat-settings',
    title: 'Interview Tutorial',
    content: 'Click this for chatbox settings.',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.interview-draw',
    title: 'Interview Tutorial',
    content: 'Use this tab to draw diagrams for the interview.',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.interview-code',
    title: 'Interview Tutorial',
    content: 'Use this tab to write code for the interview.',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.interview-feedback',
    title: 'Interview Tutorial',
    content: 'Use this tab to see the real time feedback from the interviewer.',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
];

export default function InterviewPage() {
  const { currentCode, setShowCode } = useCode();
  const { getCurrentDraw, setShowDraw } = useDraw();
  const { isAuthenticated, Logout } = useAuth();
  const [initialized, setInitialized] = useState(false);
  const [helloInterview, setHelloInterview] = useState("");
  const [problemTitle, setProblemTitle] = useState("")
  const [problemDescription, setProblemDescription] = useState("")
  const { challengeId } = useParams();
  const { showAlertDialog } = useAlertDialog();
  const [enableJoyride, setEnableJoyride] = useState(false);
  const { setRating, setFeedback } = useEval();
  const { setConcluded } = useInterviewSession();
  const [steps, setSteps] = useState(allSteps);

  useEffect(() => {
    const handlePopState = function (e) {
      if (e.persisted) {
        window.location.reload()
      }
    }

    window.addEventListener('pageshow', handlePopState)

    // 组件卸载时移除监听器
    return () => {
      window.removeEventListener('pageshow', handlePopState);
    };
  }, []);

  const sendMessage = async ({ text }) => {
    // Get svg in DrawIO
    var draw = await getCurrentDraw();
    // Get code from code mirror
    var code = currentCode;

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000);
    // Send message to backend
    try {
      const response = await fetch('/api/interview/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify((() => {
          let body = {
            ChallengeId: parseInt(challengeId),
          };
          body.Text = text.trim()
          if (code.trim() !== '') {
            body.Code = code;
          }
          if (draw && draw.trim && draw.trim() !== '') {
            body.Draw = draw;
          }
          return body;
        })()),
      });
      clearTimeout(timeoutId); // Clear timeout on successful fetch
      if (response.status === 401) {
        Logout();
        return null;
      }
      const result = await response.json();
      if (result.error) {
        throw result.error;
      }
      // setRating(result.rating);
      setFeedback(result.feedback);
      if (result.concluded) {
        setConcluded(result.concluded);
      }
      return result.message;
    } catch (error) {
      console.error('Error sending message:', error);
      showAlertDialog('Error', 'Failed to send message: ' + error);
      return null;
    }
  };

  // Initializaion in backend.
  const InitChallenge = useCallback(async () => {
    if (!isAuthenticated || initialized) {
      return;
    }
    try {
      const response = await fetch('/api/interview/init', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify((() => {
          let body = {
            ChallengeId: parseInt(challengeId),
          };
          return body;
        })()),
      });
      if (response.status === 401) {
        Logout();
      } else {
        const result = await response.json();
        if (result.error) {
          throw result.error;
        }
        setProblemTitle(result.title);
        setProblemDescription(result.description);
        setHelloInterview(result.hello_interview);
        if (result.draw_tool) {
          setShowDraw(true);
        }
        if (result.code_tool) {
          setShowCode(true);
        }
        setInitialized(true);
        setSteps(allSteps.filter(step =>
          (result.code_tool || step.target !== '.interview-code')
          && (result.draw_tool || step.target !== '.interview-draw')
        ))
        setEnableJoyride(true);
      }
    } catch (error) {
      console.error('Error initialize interview: ', error);
      showAlertDialog('Error', 'Failed to initialize interview: ' + error, () => {
        window.location.href = '/';
      });
    }
  }, [challengeId, isAuthenticated, initialized, Logout, showAlertDialog, setShowCode, setShowDraw, setEnableJoyride, setSteps]);

  useEffect(() => {
    if (isAuthenticated) {
      InitChallenge();
    }
  }, [InitChallenge, isAuthenticated]);




  return (
    <>
      <CssBaseline />
      {initialized &&
        <HelmetProvider>
          <Helmet>
            <script>{`gtag('event', 'conversion', {'send_to': 'AW-16681799656/uegOCKLbx80ZEOifwJI-'});`}</script>
          </Helmet>
        </HelmetProvider>
      }
      {enableJoyride &&
        <Joyride steps={steps} continuous={true} showSkipButton={true}
          showProgress={true}
          locale={{ back: 'Back', close: 'Close', last: 'Complete', next: 'Next', skip: 'Skip Tutorial' }} />
      }
      <Box display="flex"
        flexDirection="column"
        flexGrow="1"
        height="100vh">
        <InterviewMenu />
        <PanelGroup direction="horizontal">
          <Panel defaultSize={50} className="toolbox">
            <InterviewTools problemTitle={problemTitle} problemDescription={problemDescription} />
          </Panel>
          <PanelResizeHandle className="panel-resize-handle panel-resize-handle-vertical" />
          <Panel defaultSize={50} className="chatbox">
            <ChatBox sendMessage={sendMessage} helloInterview={helloInterview} />
          </Panel>
        </PanelGroup>
      </Box>
      <LoginDialog />
      <LoadingDialog open={!initialized && isAuthenticated} />
    </>
  );
}
