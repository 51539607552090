import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Stepper, Step, StepLabel, Tooltip, Box } from '@mui/material';
import React from 'react';

export const RatingScale = ({ rating }) => {
    const steps = [
        { label: 'N/A', icon: <HelpOutlineIcon />, value: -1 },
        { label: 'Fail', icon: <ErrorOutlineIcon />, value: 0 },
        { label: 'Pass', icon: <CheckCircleOutlineIcon />, value: 1 },
        { label: 'Perfect', icon: <EmojiEventsIcon />, value: 2 },
    ];

    return (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
            <Stepper alternativeLabel activeStep={-1}>
                {steps.map((step, index) => {
                    const isActive = step.value === rating;

                    return (
                        <Step key={index}>
                            <StepLabel
                                icon={React.cloneElement(step.icon, {
                                    color: isActive ? 'primary' : 'disabled',
                                })}
                                sx={{
                                    '& .MuiStepLabel-label': {
                                        fontWeight: isActive ? 'bold' : 'normal', // Bold text for active
                                        color: isActive ? '#1976d2' : '#9e9e9e', // Highlight text color vs gray
                                    },
                                }}
                            >
                                {step.label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
};


const RatingToRender = ({ rating, useIcon = false, hideNA = false }) => {
    if (useIcon) {
        if (rating === 0) {
            return <Tooltip title="Fail" sx={{display:"flex", alignItems:"center", justifyContent:"center"}}><ErrorOutlineIcon color='error' /></Tooltip>
        } else if (rating === 1) {
            return <Tooltip title="Pass" sx={{display:"flex", alignItems:"center", justifyContent:"center"}}><CheckCircleOutlineIcon  color='success' /></Tooltip>
        } else if (rating >= 2) {
            return <Tooltip title="Perfect" sx={{display:"flex", alignItems:"center", justifyContent:"center"}}><EmojiEventsIcon sx={{ color: '#FFD700' }} /></Tooltip>;
        } else {
            return hideNA ? "" : <Tooltip title="Not Applicable" sx={{display:"flex", alignItems:"center", justifyContent:"center"}}><HelpOutlineIcon /></Tooltip>;
        }
    }
    if (rating === 0) {
        return "Fail";
    } else if (rating === 1) {
        return "Pass";
    } else if (rating >= 2) {
        return "Perfect";
    } else {
        return "Not Applicable";
    }
};

export default RatingToRender;