import InterviewTimer from './interview-session/InterviewTimer';
import { Stack } from '@mui/material';
import InterviewRetryButton from './interview-session/RetryButton';
import InterviewExitButton from './interview-session/ExitButton';
import InterviewSaveButton from './interview-session/SaveButton';
import SurveyButton from './interview-session/SurveyButton';

export default function InterviewMenu(props) {

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Customize the message you want to show
  //     const message = "Changes you made may not be saved.";
  //     event.returnValue = message; // Standard for most browsers
  //     return message; // For some browsers
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []); // Empty dependency array means this runs once when the component mounts


  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="center" className="interview-buttons">
        <InterviewTimer />
        <InterviewRetryButton />
        <InterviewExitButton />
        <InterviewSaveButton />
        <SurveyButton />
      </Stack>
      <hr />
    </>
  );
}