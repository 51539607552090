import { useEval } from './EvalContext';
import ReactMarkdown from 'react-markdown';
import * as React from 'react';
import RatingToRender from '../data/RatingToRender';
import { useInterviewSession } from '../interview-session/InterviewSessionContext';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import { Alert, Box } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { RatingScale } from '../data/RatingToRender';

export default function EvalComponent() {
    const { rating, feedback } = useEval();
    const { recordId } = useInterviewSession();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
    <>
            { ( recordId === null || recordId === undefined ) && <><Alert severity="info">The feedback here is only for your latest response to the interviewer. To view your final rating of the interview and the comprehensive feedback report, you must submit this interview with the "Submit" button on top of the page.</Alert></> }
            { rating !== null && <RatingScale rating={rating} />}
            <ReactMarkdown>{feedback}</ReactMarkdown>
    </>
)
}