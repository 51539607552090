import { Button } from '@mui/material';
import { useInterviewSession } from './InterviewSessionContext';
import CreateIconOutlined from '@mui/icons-material/CreateOutlined';

export default function InterviewPracticeButton() {
    const { challengeId, goToPage } = useInterviewSession();
    const handlePracticeDialogOpen = () => {
        goToPage('/interview/challenge/' + challengeId);
    };

    return (
        <Button
            color="primary"
            startIcon={<CreateIconOutlined />}
            disabled={challengeId === undefined || challengeId === null || challengeId <= 0}
            onClick={handlePracticeDialogOpen}
        >
            Practice
        </Button>);
}