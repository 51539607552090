// src/ChatBox.js
import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/system';
import Box from '@mui/material/Box';
import { TextField, Typography } from '@mui/material';
import TranscriptionComponent from './TranscriptionComponent';
import SendIcon from '@mui/icons-material/Send';
import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import MessageBoxComponent from './MessageBox';
import TypingIndicator from './TypingIndicator'; // 确保路径正确
import ChatSettingsDialog from './ChatSettingsDialog';
import { useChatSettings } from './ChatSettingsContext';
require('./ChatBox.css');

export default function ChatBox(props) {
  const textFieldRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [interimInput, setInterimInput] = useState('');
  const inputRef = useRef(null); // 引入一个ref来获取输入框的高度
  const messagesEndRef = useRef(null); // 引入一个 ref 来获取消息容器的底部
  const [readOnly] = useState(props.readOnly ? props.readOnly : false)
  const [sending, setSending] = useState(true); // State to track sending state
  const [transcripting, setTranscripting] = useState(false);

  // Initializes ChatBox.
  useEffect(() => {
    if (props.helloInterview && props.helloInterview !== '' && messages.length === 0) {
      const initializedMessages = [{ text: props.helloInterview, sender: 'bot' }];
      setMessages(initializedMessages);
      setSending(false);
    }
  }, [props.helloInterview, messages.length]);

  useEffect(() => {
    if (props.fixedMessages && props.fixedMessages.length > 0) {
      const fixedMessages = JSON.parse(props.fixedMessages)
      if (!Array.isArray(fixedMessages)) {
        return
      }
      setMessages(fixedMessages.map(item => {
        return {
          sender: item.role === 0 ? 'user' : 'bot',
          text: item.text
        };
      }))
    }
  }, [props.fixedMessages])

  const onTranscriptionStart = () => {
    setInterimInput('');
    setTranscripting(true);
  }

  const onTranscriptionEnd = () => {
    setTranscripting(false);
    setInterimInput('');
  }

  const onTranscriptionChange = (interimTranscript, finalTranscript) => {
    if (finalTranscript.length > 0) {
      setInput((input) => input + finalTranscript);
    }
    setInterimInput(interimTranscript);
    if (textFieldRef.current) {
      // Scroll to the end of the TextField's content
      textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
    }
  }

  const handleSend = async () => {
    if (input.trim() === '') {
      return;
    }

    // Disable textarea and button
    setSending(true);
    const originalMessages = messages;
    const userMessage = { text: input.trim(), sender: 'user' }
    const updatedMessagesWithUser = [...originalMessages, userMessage];
    setMessages(updatedMessagesWithUser);
    try {
      const result = await props.sendMessage({ text: input.trim() });
      if (!result) {
        throw new Error("Failed to send message");
      }
      const botResponse = { text: result, sender: 'bot' };
      const updatedMessagesWithBot = [...updatedMessagesWithUser, botResponse];
      setMessages(updatedMessagesWithBot);
      setInput('');
      setInterimInput('');
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(originalMessages);
    } finally {
      // After processing, unfreeze textarea and button
      setSending(false);
    }
    // 重置输入框高度
    // inputRef.current.style.height = 'auto';   
  };


  const { settings } = useChatSettings();

  const handleKeyDown = (e) => {
    if (settings.sendOnEnter) {
      // settings.sendOnEnter 为 true 时
      if (e.key === 'Enter') {
        if (!e.ctrlKey) {
          handleSend();
          e.preventDefault(); // 阻止默认行为（添加新行）
        } else {
          return;
        }
      }
    } else {
      // settings.sendOnEnter 为 false 时
      if (e.key === 'Enter') {
        if (e.ctrlKey) {
          handleSend(); // Ctrl+Enter 发送消息
          e.preventDefault(); // 阻止默认行为（添加新行）
        }
        // 如果没有 Ctrl 键，默认行为是添加新行
      }
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    // 设置输入框的高度为内容的实际高度，但不超过最大行数
    // inputRef.current.style.height = 'auto';
    // inputRef.current.style.height = Math.min(inputRef.current.scrollHeight, MAX_ROWS * 20) + 'px';
  };

  useEffect(() => {
    // 滚动到消息容器的底部
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto', block: "end" });  // 'auto'
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [messages.length, sending, textFieldRef]); // 当 messages 更新时触发

  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '8px', // rounded corners
        // padding: '16px', // internal padding
        margin: '16px', // external margin
        height: 'calc(100% - 50px)'
      }}
    >
      <Box sx={{ padding: '8px', display: 'flex', alignItems: 'center', justifyContent:"center", color: theme.palette.primary.main, }}>
        <Avatar sx={{ marginRight: '8px' }} />
        <Typography sx={{ flexGrow: 1 }} variant="h5"><b>Interviewer</b></Typography>
        {!readOnly && (<Typography><ChatSettingsDialog /></Typography>)}
      </Box>
      {/* {messages.length === 0 && (<Alert severity="info">You may start the interview by saying "Hi".</Alert>)} */}
      <Box className="messages" flexGrow={1} overflow="auto" sx={{ padding: '10px' }} >
        {messages.map((msg, index) => (
          <MessageBoxComponent key={index} sender={msg.sender}>
            {msg.text ? msg.text : ''}
          </MessageBoxComponent>
        ))}
        {/* 一个空的 div 用于滚动到底部 */}
        <Box ref={messagesEndRef} sx={{ fontStyle: 'italic' }}>
          {sending && !readOnly && <TypingIndicator />}
        </Box>
      </Box>
      {!readOnly &&
        <Box className="chat-input-box" sx={{ display: 'flex', padding: '10px' }}>
          {('webkitSpeechRecognition' in window) &&
            <TranscriptionComponent className="chat-transcription-button" onTranscriptionChange={onTranscriptionChange} onTranscriptionStart={onTranscriptionStart} onTranscriptionEnd={onTranscriptionEnd} disabled={sending} />}
          <TextField
            inputRef={textFieldRef}
            sx = {{
              ml: 1,
              mr: 1,
            }}
            fullWidth
            multiline
            className="chat-textarea"
            rows={4}
            // minRows={4}
            // maxRows={4}
            maxLength={1000}
            value={input + interimInput}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            placeholder={transcripting ? "Speak to see text here..." : "Type your message here..."}
            disabled={sending || (interimInput.length > 0)}
          />
          <IconButton
            className="chat-send-button"
            sx={{
              backgroundColor: 'white',
              color: theme.palette.primary.main,
              outline: 0,
              width: '56px',
              height: '56px',
              '&:hover': {
                color: theme.palette.primary.dark, // 背景颜色在 hover 状态下变暗
              },
            }}
            onClick={() => handleSend()} disabled={sending}>
            <SendIcon />
          </IconButton>
        </Box>
      }
    </Box>
  );
}