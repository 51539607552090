import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import { Pause, PlayArrow } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        h2: {
            fontSize: '1.5rem',
            fontWeight: 500,
        },
    },
});


const InterviewTimer = () => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [startTime, setStartTime] = useState(Date.now());

    useEffect(() => {
        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                setElapsedTime(Date.now() - startTime);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isRunning, startTime]);

    const handlePause = () => {
        setIsRunning(false);
    };

    const handleContinue = () => {
        setStartTime(Date.now() - elapsedTime);
        setIsRunning(true);
    };



    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                // elevation={3}
                sx={{
                    padding: 0,
                    textAlign: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'inline-block',
                }}
                className="interview-timer-button"
            >
                <Typography variant="h2" color="primary" 
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'center',
                        }}>
                    {formatTime(elapsedTime)}
                </Typography>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0,0,0,0)',
                        transition: 'background-color 0.3s',
                        opacity: 0,
                    }}

                    onMouseEnter={e => {
                        e.currentTarget.style.backgroundColor = 'rgba(0,0,0,0.2)';
                        e.currentTarget.style.opacity = 1;
                    }}
                    onMouseLeave={e => {
                        e.currentTarget.style.backgroundColor = 'rgba(0,0,0,0)';
                        e.currentTarget.style.opacity = 0;
                    }}
                >
                    {isRunning ? (
                        <IconButton
                            onClick={handlePause}
                            sx={{
                                opacity: 0.7,
                                color: '#ffffff'
                            }}
                        >
                            <Pause fontSize="large" />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={handleContinue}
                            sx={{
                                opacity: 0.7,
                                color: '#ffffff'
                            }}
                        >
                            <PlayArrow fontSize="large" />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    );
};
export default InterviewTimer;