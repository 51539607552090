import { useState } from "react";
import { useInterviewSession } from "./InterviewSessionContext";
import { Button, Tooltip } from "@mui/material";
import AssignmentTurnedInIconOutlined from '@mui/icons-material/AssignmentTurnedInOutlined';
import LoadingDialog from "../dialogs/LoadingDialog";
import Joyride from "react-joyride";
import { useConfirmDialog } from "../dialogs/ConfirmDialogContext";

export default function InterviewSaveButton() {
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const { saveInterviewSession, recordId, concluded } = useInterviewSession();
  const { showConfirmDialog } = useConfirmDialog();
  const steps = [
    {
      target: '.interview-save-button',
      content: 'Please feel free to continue the interview. To view your interview rating and the comprehensive feedback report, you must finalize and save the interview by clicking this button.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
  ];

  return (
    <div>
      <Joyride steps={steps} run={concluded && recordId === null} />
      <Tooltip title="It is too early to submit. Please keep the interview going."
        disableHoverListener={concluded && recordId === null}>
        <span><Button
          color="success"
          className="interview-save-button"
          disabled={!concluded || recordId !== null}
          startIcon={<AssignmentTurnedInIconOutlined />}
          onClick={async () => {
            showConfirmDialog(
              'Submit Interview',
              'After submission, you will exit the current interview as the interview is finalized. You will be able to view your rating and feedback report after submission.',
              async () => {
                setShowLoadingDialog(true);
                await saveInterviewSession();
                setShowLoadingDialog(false);
              }
            );

          }}
        >
          Submit
        </Button></span>
      </Tooltip>
      <LoadingDialog open={showLoadingDialog} />
    </div>
  )
}