import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import InterviewPage from './InterviewPage'
import Challenges from './Challenges'
import Home from './Home'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InterviewRecord from './InterviewRecord';
import InterviewRecordList from './InterviewRecordList';
import { DrawProvider } from './interview-tools/DrawContext';
import { EvalProvider } from './interview-tools/EvalContext';
import { CodeProvider } from './interview-tools/CodeContext';
import ConfirmDialog from './dialogs/ConfirmDialog';
import { ConfirmDialogProvider } from './dialogs/ConfirmDialogContext';
import AlertDialog from './dialogs/AlertDialog';
import { AlertDialogProvider } from './dialogs/AlertDialogContext';
import { ChatSettingsProvider } from './interview-chat/ChatSettingsContext';
import ChallengeRecordList from './ChallengeRecordList';
import TermsAndConditions from './TermsAndConditions';
import { InterviewSessionProvider } from './interview-session/InterviewSessionContext';
import InvitationCodes from './InvitationCodes';
import { VoiceProvider } from './interview-chat/VoiceContext';
import YoutubeVideo from './YoutubeVideo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#f50057',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#fb8c00',
    },
    info: {
      main: '#0288d1',
    },
    success: {
      main: '#2e7d32',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
      userMessage: '#E3F2FD',
      botMessage: '#FFFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
      userMessage: '#1976d2',
      botMessage: '#37474F',
    },
    border: {
      userMessage: '#90CAF9',
      botMessage: '#B0BEC5',
    },
  },
  typography: {
    h1: {
      fontSize: '2.125rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    button: {
      textTransform: 'none',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmDialogProvider>
        <AlertDialogProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/practice" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/invitation_codes" element={<InvitationCodes />} />
              <Route path="/practice" element={<Challenges />} />
              <Route path="/learning/video/:challengeId" element={<YoutubeVideo />} />
              <Route path="/interview/challenge/:challengeId" element={
                <VoiceProvider>
                  <EvalProvider>
                    <CodeProvider>
                      <DrawProvider readonly={false}>
                        <InterviewSessionProvider>
                          <ChatSettingsProvider>
                            <InterviewPage />
                          </ChatSettingsProvider>
                        </InterviewSessionProvider>
                      </DrawProvider>
                    </CodeProvider>
                  </EvalProvider>
                </VoiceProvider>
              } />
              <Route path="/interview/record/:recordId" element={
                <VoiceProvider>
                  <EvalProvider>
                    <CodeProvider>
                      <DrawProvider readonly={true}>
                        <InterviewSessionProvider>
                          <ChatSettingsProvider>
                            <InterviewRecord />
                          </ChatSettingsProvider>
                        </InterviewSessionProvider>
                      </DrawProvider>
                    </CodeProvider>
                  </EvalProvider>
                </VoiceProvider>
              } />
              <Route path="/interview/records" element={<InterviewRecordList />} />
              <Route path="/records/challenge/:challengeId" element={
                <ChallengeRecordList />
              } />
            </Routes>
          </Router>
          <ConfirmDialog /><AlertDialog />
        </AlertDialogProvider>
      </ConfirmDialogProvider>
    </ThemeProvider>
  );
}

export default App;
