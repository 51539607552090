import Navbar from './Navbar'
import * as React from 'react';
import { useState } from 'react';
import { Tabs, Tab, Box, Typography, Link } from '@mui/material';
import PaginatedTable from './data/PaginatedTable';
import { Button } from '@mui/material';
import CreateIconOutlined from '@mui/icons-material/CreateOutlined';
import VisibilityIconOutlined from '@mui/icons-material/VisibilityOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useAuth } from './auth/AuthContext';
import RatingToRender from './data/RatingToRender';
import { styled } from '@mui/material/styles';
import config from './config';
import DrawIcon from '@mui/icons-material/Draw';
import DrawIconOutlined from '@mui/icons-material/DrawOutlined';
import CodeIcon from '@mui/icons-material/Code';
import CodeIconOutlined from '@mui/icons-material/CodeOutlined';
import { useParams, useSearchParams } from 'react-router-dom';

const TabHeight = '48px';

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.primary.main, // Adjust color as needed
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
    minHeight: TabHeight, height: TabHeight,
}));

export default function Challenges() {
    const {isAuthenticated} = useAuth();
    const [searchParams] = useSearchParams();
    const defaultTag = searchParams.get('defaultTag');
    const allListTypes = ['System Design', 'Coding']; // 预设的tag列表
    const [selectedListType, setSelectedListType] = useState(
        allListTypes.includes(defaultTag) ? defaultTag : allListTypes[0]
    );
    const columns = [
        { field: 'rating', headerName: '', renderCell:
            (row) => { return (<RatingToRender rating={row.rating} useIcon={true} hideNA={true} />); }},
        // { field: 'id', headerName: 'ID' },
        { field: 'title', headerName: 'Title', renderCell:
            (row) => { return <Link underline="none" href={`/interview/challenge/${row.id}`}>{row.title}</Link> }
         },
        {
            field: 'actions', headerName: 'Actions', renderCell:
                (row) => {
                    return (
                        <Box sx={{gap:1, flexDirection:"row", display: 'flex',}}>
                            <Box><Button color="primary" href={`/interview/challenge/${row.id}`} startIcon={<CreateIconOutlined />}>Practice</Button></Box>
                            {row.has_video && <Box><Button color="info" href={`/learning/video/${row.id}`} startIcon={<YouTubeIcon />}>Learn</Button></Box>}
                            {row.rating >= 0 && <Box><Button color="info" href={`/records/challenge/${row.id}`} startIcon={<VisibilityIconOutlined />}>Solutions</Button></Box>}
                        </Box>
                    )
                }
        }
    ];

    const fetchData = React.useCallback(async (page, rowsPerPage) => {
        try {
            const response = await fetch('/api/challenges/list?tag='+selectedListType);
            const result = await response.json();
            if (result.error) {
                return result;
            }
            return {
                data: result,
                total: result.length,
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    }, [selectedListType]);

    return (
        <>
            <Navbar />
            <Box
                display="flex"
                width="100%"
                justifyContent="center"
            >
                <Box
                    width="90vw"
                    maxWidth="1000px"
                    sx={{
                        m: 5
                    }}
                >
                    <Tabs
                        value={selectedListType}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(event, value)=>{ setSelectedListType(value) }}
                        aria-label="list type tabs"
                        // variant="scrollable"
                        scrollButtons="auto"
                        centered
                    >
                        <StyledTab iconPosition='start' icon={selectedListType==="System Design"?<DrawIcon/>:<DrawIconOutlined/>} key="System Design" label="System Design" value="System Design" />
                        <StyledTab iconPosition='start' icon={selectedListType==="Coding"?<CodeIcon/>:<CodeIconOutlined/>} key="Coding" label="Coding" value="Coding" />
                    </Tabs>
                    <PaginatedTable
                        rowsPerPage={null}
                        columns={isAuthenticated?columns:columns.slice(1)}
                        fetchData={fetchData}
                        isRowClickable={(row) => { return false; }}
                        onRowClick={(row) => {
                            window.location.href = `/interview/challenge/${row.id}`;
                        }}
                    />
                    <Typography variant="h5" marginTop={1} marginBottom={6} textAlign={"center"}>Have new questions to add? Please submit <a rel="noreferrer" target="_blank" href={config.newQuestionFormUrl}>here</a>.</Typography>
                </Box>
            </Box>
        </>
    )
}
