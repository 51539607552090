import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const NotificationBar = ({ message }) => {
  return (
    message === "" ? "" :
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'rgb(36, 58, 94)',
        color: 'rgb(80, 230, 255)',
        height: '40px', // 调整高度
        boxShadow: 'none', // 去掉阴影
        display: 'flex',
        alignItems: 'center', // 垂直居中
        justifyContent: 'center' // 水平居中
      }}
    >
      <Toolbar
        sx={{ height: '100%', justifyContent: 'center' }}
      >
        <Container>
          <Typography variant="body2" color="inherit"
            sx={{fontWeight:'bold'}}
            dangerouslySetInnerHTML={{ __html: message }}>
          </Typography>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default NotificationBar;
