import React from 'react';

const LocalTimeConverter = ({ utcTime }) => {
  // 创建一个Date对象，传入UTC时间字符串
  const date = new Date(utcTime);

  // 使用 Intl.DateTimeFormat 定义自定义格式
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // 24小时制
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(date).replace(',', '');

  return (
    <>
        {formattedDate}
    </>
  );
};

export default LocalTimeConverter;