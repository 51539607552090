import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Dot = styled('div')(({ theme }) => ({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  margin: '0 4px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  animation: `${bounce} 1.4s infinite both`,
  '&:nth-of-type(1)': {
    animationDelay: '0s',
  },
  '&:nth-of-type(2)': {
    animationDelay: '0.2s',
  },
  '&:nth-of-type(3)': {
    animationDelay: '0.4s',
  },
}));

const TypingIndicator = () => {
  return (
    <Box display="flex" alignItems="center">
      <Typography>
        Interviewer is typing
      </Typography>
      <Dot />
      <Dot />
      <Dot />
    </Box>
  );
};

export default TypingIndicator;