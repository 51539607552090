import { Button } from '@mui/material';
import SurveyIconOutlined from '@mui/icons-material/AssignmentOutlined';
import config from '../config';

export default function SurveyButton() {
    return (
        <Button
            color="primary"
            startIcon={<SurveyIconOutlined />}
            href={config.surveyUrl}
            target="_blank"
        >
            Take Survey
        </Button>
    );
}
