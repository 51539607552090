import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/system';
import { Tab, Tabs } from '@mui/material';
import DrawIO from './DrawIO';
import BaseTypography from '@mui/material/Typography';
import CodeMirrorComponent from './CodeMirrorComponent';
import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';
import EvalComponent from './EvalComponent';
import { useDraw } from './DrawContext';
import { useCode } from './CodeContext';
import DrawIcon from '@mui/icons-material/Draw';
import DrawIconOutlined from '@mui/icons-material/DrawOutlined';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FeedbackIconOutlined from '@mui/icons-material/FeedbackOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionIconOutlined from '@mui/icons-material/DescriptionOutlined';
import CodeIcon from '@mui/icons-material/Code';
import CodeIconOutlined from '@mui/icons-material/CodeOutlined';

const TabHeight = "48px";

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.main, // Adjust color as needed
  '&.Mui-selected': {
    fontWeight: 'bold',
  },
  minHeight: TabHeight, height: TabHeight,
}));

const Typography = styled(BaseTypography)(()=> ({
  paddingLeft: 10,
  paddingRight: 10,
  height: "100%",
  boxSizing: "border-box"
}));

export default function InterviewTools(props) {
  const [currentTab, setCurrentTab] = useState(props.showFeedbackTab ? 3 : 0);
  const {showDraw} = useDraw();
  const {showCode} = useCode();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        border: '2px solid #1976d2', // solid border with primary color
        borderRadius: '15px', // rounded corners
        paddingBottom: '16px', // internal padding
        margin: '16px', // external margin
        height: 'calc(100% - 50px)',
        overflow: 'hidden'
      }}
    >
      <Tabs value={currentTab} onChange={handleChange} orientation="horizontal" variant="fullWidth" 
        sx={{
          mb:2,
          minHeight: TabHeight, height: TabHeight,
          alignItems: "center",
          justifyContent: "center",
        }}>
          <StyledTab icon={currentTab===0? <DescriptionIcon /> : <DescriptionIconOutlined />} iconPosition="start" value={0} className="interview-problem" label="Question"></StyledTab>
          {showDraw && <StyledTab icon={currentTab===1?<DrawIcon /> : <DrawIconOutlined />} iconPosition="start" value={1} className="interview-draw" label="Draw"></StyledTab>}
          {showCode && <StyledTab icon={currentTab===2?<CodeIcon /> : <CodeIconOutlined />} iconPosition="start" value={2} className="interview-code" label="Code"></StyledTab>}
          <StyledTab icon={currentTab===3?<FeedbackIcon /> : <FeedbackIconOutlined />} iconPosition="start" value={3} className="interview-feedback" label="Feedback"></StyledTab>
      </Tabs>
        <Typography component="div" value={0} hidden={currentTab !== 0}
          style={{ height: 'calc(100% - 60px)', overflowY: 'auto' }} >
          <b>{props.problemTitle}</b>
          <ReactMarkdown>
            {props.problemDescription}
          </ReactMarkdown>
        </Typography>
        {showDraw && 
          <Typography sx={{ paddingBottom: "100px;" }} component="div" value={1} hidden={currentTab !== 1} >
            <DrawIO />
            {/* <ExcalidrawComponent ref={props.excalidrawRef} /> */}
          </Typography>
        }
        {showCode &&
          <Typography sx={{ paddingBottom: "100px;" }} component="div" value={2} hidden={currentTab !== 2} >
            <CodeMirrorComponent />
          </Typography>
        }
        <Typography component="div" value={3} hidden={currentTab !== 3}
          style={{ height: 'calc(100% - 60px)', overflowY: 'auto' }}>
          <EvalComponent />
        </Typography>
    </Box>
  );
}
