import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useChatSettings } from './ChatSettingsContext';
import SettingsIconOutlined from '@mui/icons-material/SettingsOutlined';

const ChatSettingsDialog = () => {
  const [open, setOpen] = useState(false);
  const { settings, toggleSetting } = useChatSettings();
  const onClose = () => setOpen(false);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Chat Settings</DialogTitle>
        <DialogContent dividers={true}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={settings.sendOnEnter}
                  onChange={() => toggleSetting('sendOnEnter')}
                />
              }
              label="Send message when press 'Enter'"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={settings.textToSpeech}
                  onChange={() => toggleSetting('textToSpeech')}
                />
              }
              label="Enable audio for interviewer's message"
            />
              <FormGroup sx={{paddingLeft: 4}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.autoPlaySpeech}
                      onChange={() => toggleSetting('autoPlaySpeech')}
                      disabled={!settings.textToSpeech} // Disable if playAudio is off
                    />
                  }
                  label="Auto-play audio after receiving interviewer's message"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.hideText}
                      onChange={() => toggleSetting('hideText')}
                      disabled={!settings.textToSpeech} // Disable if playAudio is off
                    />
                  }
                  label="Hide interviewer's text"
                />
              </FormGroup>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <SettingsIconOutlined className="chat-settings" onClick={() => setOpen(true)} style={{ cursor: 'pointer' }} />
    </>
  );
};

export default ChatSettingsDialog;
