import { useConfirmDialog } from "../dialogs/ConfirmDialogContext";
import { Button } from '@mui/material';
import ReplayIconOutlined from '@mui/icons-material/ReplayOutlined';
import { useInterviewSession } from "./InterviewSessionContext";

export default function InterviewRetryButton() {
    const { showConfirmDialog } = useConfirmDialog();
    const { goToPage} = useInterviewSession();

    const handleRetryDialogOpen = () => {
        showConfirmDialog(
            'Restart Interview',
            'You are about to restart the interview. Any unsubmitted progress will be lost.',
            () => {
                goToPage();
            }
        );
    }

    return (
        <Button
            color="primary"
            onClick={handleRetryDialogOpen}
            startIcon={<ReplayIconOutlined />}
        >
            Retry
        </Button>);
}