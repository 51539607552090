import Markdown from 'react-markdown';
import Navbar from './Navbar'
import React from 'react';
import { Box } from '@mui/system';
import BaseTypography from '@mui/material/Typography';
import config from './config';

export default function TermsAndConditions() {
    
    const input = `## Terms and Conditions
    
### Last Updated: July 24, 2024

Welcome to {process.env.WEBSITE_NAME} ("we," "our," "us"). By using our website and services, you agree to these Terms and Conditions. Please read them carefully.

### 1. **Acceptance of Terms**

By accessing or using our website, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to these terms, please do not use our website.

### 2. **Services**

{process.env.WEBSITE_NAME} provides a mock interview service where users interact with an AI for interview practice. Our services include:

- Simulated interview sessions with AI.
- Analysis and feedback on interview performance.
- Storage of interview data for improving services.

### 3. **User Accounts**

To use our services, you must create an account using Google Login. By doing so, you agree to provide accurate, complete, and updated information. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.

### 4. **Data Collection and Use**

We may collect and store data related to your interactions with our AI, including but not limited to:

- Interview performance data.
- Transcripts and recordings of interviews.

This data may be used for the following purposes:

- To improve our services and AI algorithms.
- To provide personalized feedback and recommendations.
- To contact you for service-related updates or promotions.

### 5. **Data Sharing**

We may share your data with third-party service providers and AI models to enhance our services. Third-party AI models and service providers may access details about your interactions with our services for improvement purposes. Avoid sharing sensitive personal information (e.g., credit card details) through our services.

### 6. **Email Communications**

By using our services, you consent to receive communications from us via email. This may include:

- Service-related updates.
- Promotional offers and advertisements.

You can opt out of promotional emails by following the unsubscribe instructions provided in those emails.

### 7. **Intellectual Property**

All content, features, and functionality on our website, including but not limited to text, graphics, logos, and software, are the exclusive property of {process.env.WEBSITE_NAME} and are protected by intellectual property laws.

### 8. **User Conduct**

You agree to use our services in a lawful manner and in accordance with these Terms and Conditions. Prohibited activities include:

- Using our services for any illegal or unauthorized purpose.
- Interfering with or disrupting the operation of our website.
- Attempting to gain unauthorized access to any part of our website or its related systems.

### 9. **Limitation of Liability**

To the fullest extent permitted by law, {process.env.WEBSITE_NAME} shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services.

### 10. **Indemnification**

You agree to indemnify and hold harmless {process.env.WEBSITE_NAME} and its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising out of or related to your use of our services or violation of these Terms and Conditions.

### 11. **Modifications**

We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this page with an updated effective date. Your continued use of our services following any changes constitutes acceptance of the new Terms and Conditions.

### 12. **Termination**

We may terminate or suspend your account and access to our services at our sole discretion, without notice, for any reason, including but not limited to a violation of these Terms and Conditions.
    `

    const replacedInput = input.replace(/{process\.env\.WEBSITE_NAME}/g, config.websiteName);

    return (
        <><Navbar />
    <Box
      sx={{
        border: '2px solid #1976d2', // solid border with primary color
        borderRadius: '15px', // rounded corners
        paddingBottom: '16px', // internal padding
        margin: '16px', // external margin
        overflow: 'hidden'
      }}>
        <BaseTypography component="div" 
     style={{ margin: '20px', padding: '10px',
            maxWidth: '100vw',
            overflow: 'auto',  // 确保内容可以在超出宽度时滚动
            wordWrap: 'break-word',  // 确保长单词也会换行
            overflowWrap: 'break-word',  // 为长单词提供换行支持
            boxSizing: 'border-box'
        }}>
        <Markdown components={{ p: React.Fragment }}>{replacedInput}</Markdown>
        </BaseTypography>    </Box>

        </>
    
    )
}