// FileName - Navbar.js

// Importing files from Material-UI
import React, {useState} from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useAuth } from './auth/AuthContext';
import LogoutIconOutlined from '@mui/icons-material/LogoutOutlined';
import CreateIconOutlined from '@mui/icons-material/CreateOutlined';
// import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIconOutlined from '@mui/icons-material/FormatListBulletedOutlined';
import config from "./config";
import GroupIconOutlined from '@mui/icons-material/GroupOutlined';
import NotificationBar from "./NotificationBar";
import SurveyButton from "./interview-session/SurveyButton";
import LoginIconOutlined from '@mui/icons-material/LoginOutlined';
import LoginDialog from "./dialogs/LoginDialog";
import { useTheme } from "@mui/material";

export default function Navbar() {
    const { isAuthenticated, Logout } = useAuth();
    const [ openLoginDialog, setOpenLoginDialog ] = useState(false);
    const theme = useTheme();

    return (
        <Box sx={{ flexGrow: 1, }}>
            <NotificationBar message={config.topNotice} />
            <AppBar position="static" sx={{
                backgroundColor: "#ffffff",
                color: theme.palette.primary.main,
            }}>
                <Toolbar variant="dense" position="static">
                    <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                        <Typography
                            variant="h5"
                            color="inherit"
                            sx={{ marginRight: 2 }}
                        >
                            {config.websiteName}
                        </Typography>
                        {/* <Button color="inherit" href="/home" startIcon={<HomeIcon />}>
                            Home
                        </Button> */}

                        <Button sx={{mr:1}} color="inherit" href="/practice" startIcon={<CreateIconOutlined />}>
                            Practice
                        </Button>
                        {!isAuthenticated && (
                            <Button sx={{mr:1}} color="inherit" onClick={()=>{setOpenLoginDialog(true);}} startIcon={<LoginIconOutlined />}>
                                Login
                            </Button>
                        )}
                        {isAuthenticated && (
                            <Button sx={{mr:1}} color="inherit" href="/interview/records" startIcon={<FormatListBulletedIconOutlined />}>
                                My Submissions
                            </Button>
                        )}
                        {isAuthenticated && config.requireInvitation && (
                            <Button sx={{mr:1}} color="inherit" href="/invitation_codes" startIcon={<GroupIconOutlined />}>
                                Invite Others
                            </Button>
                        )}
                        {isAuthenticated && (
                            <Button sx={{mr:1}} color="inherit" onClick={()=>{Logout(); window.location.href='/'; }} startIcon={<LogoutIconOutlined />}>
                                Logout
                            </Button>
                        )}
                    </Box>
                    <Box>
                        <SurveyButton />
                    </Box>
                </Toolbar>
            </AppBar>
            <LoginDialog open={openLoginDialog} onClose={()=>{setOpenLoginDialog(false);}} />
        </Box>
    );
}