import React, { createContext, useState, useContext, useCallback } from 'react';

const AlertDialogContext = createContext();

export const AlertDialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState({
    open: false,
    title: '',
    content: '',
    onConfirm: () => {},
  });

  const showAlertDialog = useCallback((title, content, onConfirm = () => {}) => {
    setDialogState({ open: true, title, content, onConfirm });
  }, [setDialogState]);

  const hideAlertDialog = useCallback(() => {
    setDialogState({ ...dialogState, open: false });
  }, [setDialogState, dialogState]);

  return (
    <AlertDialogContext.Provider value={{ dialogState, showAlertDialog, hideAlertDialog }}>
      {children}
    </AlertDialogContext.Provider>
  );
};

export const useAlertDialog = () => {
  return useContext(AlertDialogContext);
};