import Navbar from './Navbar'
import * as React from 'react';
import { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import PaginatedTable from './data/PaginatedTable';
import CopyButton from './dialogs/CopyButton';
import { useAuth } from './auth/AuthContext';
import LoginDialog from './dialogs/LoginDialog';

export default function InvitationCodes() {
    const { isAuthenticated, Logout } = useAuth();

    const columns = [
        { field: 'id', headerName: 'Code'},
        { field: 'used_at', headerName: 'Status', renderCell: (row)=>{ return row.used_at ? "Used" : "Available"; } },
        {
            field: 'actions', headerName: '', renderCell:
                (row) => {
                    return (
                        row.used_at ?  <CopyButton text={row.id} disabled={true} /> : <CopyButton text={row.id} />
                    )
                }
        }
    ];

    const fetchData = useCallback(async (page, rowsPerPage) => {
        try {
            const response = await fetch('/api/user/invitation_codes');
            if (response.status === 401) {
                Logout();
                return null;
            }
            const result = await response.json();
            if (result.error) {
                throw result.error;
            }
            return {
                data: result,
                total: result.length,
                title: "Invitation Codes",
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    }, [Logout]);

    return (
        <>
            <Navbar />
            <Box
                display="flex"
                width="100%"
                justifyContent="center"
            >
                <Box
                    width="500px"
                    sx={{
                        m: 5
                    }}
                >
                    {isAuthenticated &&
                        <PaginatedTable
                            rowsPerPage={null}
                            columns={columns}
                            fetchData={fetchData}
                            isRowClickable={(row) => { return false; }}
                            onRowClick={(row) => {} }
                        />
                    }
                </Box>
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
                <Typography variant='h5' width="800px" justifyContent="center" textAlign="center">
                    Please ask your friends take surveys to help us. Link at top-right corner on the page.
                </Typography> 
            </Box>
            <LoginDialog />
        </>
    )
}
