import { Button } from "@mui/material"
import ShareIconOutlined from '@mui/icons-material/ShareOutlined';
import { useInterviewSession } from "./InterviewSessionContext";
import ShareRecordDialog from "./shareRecordDialog";
import { useState } from "react";

export default function InterviewShareButton() {
  const { isOwner } = useInterviewSession();
  const [ showShareDialog, setShowShareDialog] = useState(false);
  // const steps = [
  //     {
  //       target: '.interview-share-button',
  //       content: 'Please use this button to share the interview record',
  //       disableBeacon: true,
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //     },
  //   ];

  return (
    <div>
    <Button
      color="success"
      className="interview-share-button"
      startIcon={<ShareIconOutlined />}
      onClick={async () => {
        setShowShareDialog(true);
      }}
      disabled={!isOwner}
    >
      Share
    </Button>
    {/* <Joyride steps={steps} run={isOwner && !shared} /> */}
    <ShareRecordDialog
          open={showShareDialog}
          onClose={()=>{setShowShareDialog(false);}} />
          </div>
  );
}