import Navbar from './Navbar'
import { useEffect, useState } from 'react';
import CenteredYoutubeVideo from './data/YoutubeViewer';
import { useParams } from 'react-router-dom';

export default function YoutubeVideo() {
    const { challengeId } = useParams();
    const [url, setUrl] = useState(null)

    useEffect(async () => {
        try {
          const response = await fetch('/api/learning/video?challengeId='+challengeId);
          const result = await response.json();
          if (result.error) {
              throw result.error;
          }
          setUrl(result.url);
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    }, [challengeId, setUrl])

    return (
        <>
            <Navbar />
            <CenteredYoutubeVideo url={url} />
        </>
    )
}
