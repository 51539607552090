import React, { createContext, useState, useContext } from 'react';

// Define the context and a provider for it
const ChatSettingsContext = createContext();

export const ChatSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    // Text to speech
    textToSpeech: false,
    autoPlaySpeech: false,
    hideText: false,

    // Control
    sendOnEnter: false,
  });

  const toggleSetting = (setting) => {
    if (setting === 'textToSpeech' && settings['textToSpeech'] === true) {
      if (settings['autoPlaySpeech'] === true) {
        toggleSetting('autoPlaySpeech');
      }
      if (settings['hideText'] === true) {
        toggleSetting('hideText');
      }
    }
    setSettings(prevSettings => ({
      ...prevSettings,
      [setting]: !prevSettings[setting],
    }));
  };

  return (
    <ChatSettingsContext.Provider value={{ settings, toggleSetting }}>
      {children}
    </ChatSettingsContext.Provider>
  );
};

export const useChatSettings = () => useContext(ChatSettingsContext);