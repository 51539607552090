import React from 'react';
import { Box, styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import TextToSpeechToggle from './TextToSpeech';
import './MessageBox.css';
import { useChatSettings } from './ChatSettingsContext';

const MessageBox = styled(Box)(({ theme, sender }) => ({
    padding: '8px 12px',
    border: `1px solid ${sender === 'user' ? theme.palette.border.userMessage : theme.palette.border.botMessage}`,
    borderRadius: '10px',
    borderTopLeftRadius: sender === 'bot' ? '0px' : '10px',
    borderBottomRightRadius: sender === 'user' ? '0px' : '10px',
    maxWidth: '90%',
    marginBottom: '8px',
    position: 'relative',
    backgroundColor: sender === 'user' ? theme.palette.background.userMessage : theme.palette.background.botMessage,
    color: sender === 'user' ? theme.palette.text.userMessage : theme.palette.text.botMessage,
    alignSelf: sender === 'user' ? 'flex-end' : 'flex-start',
    marginLeft: sender === 'user' ? 'auto' : 'initial',
    marginRight: sender === 'user' ? '0' : 'auto',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
}));

const MessageBoxComponent = ({ sender, children }) => {
    const { settings } = useChatSettings();
    return (
        <MessageBox sender={sender} className="message-box">
                <Box display="flex" flexDirection="row">
                    {sender !== 'user' && <TextToSpeechToggle text={children} />}
                    <Box flexGrow={1} sx={{ display: (sender === 'user' || !settings.hideText) ? 'block' : 'none' }}>
                        <ReactMarkdown children={children} />
                    </Box>
                </Box>
        </MessageBox>
    );
};

export default MessageBoxComponent;
