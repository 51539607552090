// CopyButton.js
import React, { useState } from 'react';
import { Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyButton = ({ text, disabled = false }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    };

    return (
        <CopyToClipboard text={text} onCopy={handleCopy}>
            <Button disabled={disabled}
                color={copied ? "success" : "primary"}
                sx={{ ml: 2, width: 100 }}
                startIcon={<ContentCopyIcon />}
            >
                {copied ? "Copied" : "Copy"}
            </Button>
        </CopyToClipboard>
    );
};

export default CopyButton;
